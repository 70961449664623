<template>
  <a-card :bordered="false" class="card-area" @keyup.enter.native="search">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex:0">
<!--        <div class="one-item ">-->
<!--          <label>名称</label>-->
<!--          <a-input class="input" v-model="queryParams.dataName" allowClear placeholder="请输入名称"/>-->
<!--        </div>-->
      </div>
      <div class="operator">
        <a-button type="primary" @click="search">查询</a-button>
        <a-button type="success" icon="plus" @click="addChild()">新增行业</a-button>
        <!--        <a-button icon="import" v-hasPermission="'brand:import'"-->
        <!--                  style="margin-left: 8px">节点导入-->
        <!--        </a-button>-->
        <!--        <a-button icon="import" v-hasPermission="'course:import'"-->
        <!--                  style="margin-left: 8px">资料导入-->
        <!--        </a-button>-->
      </div>
    </div>
    <div style="display: flex">
      <div style="flex: 1;max-width: 650px;margin-right:25px">
        <TreeGrid style="min-height:250px;max-height:700px;"
                  :data="data"
                  idField="id"
                  :animate="true"
                  treeField="dataName"
                  @rowExpand="rowExpand"
                  @rowClick="nodeClick"
                  :cascadeCheck="false"
        >
          <!--                <GridColumn field="" title="" width="30" align="center">-->
          <!--                    <template slot="cell" slot-scope="{row}">-->
          <!--                        <img v-if="row.flag==1" :src="row.picUrl|fullImgPath" preview="1"-->
          <!--                             style="width:20px;height:20px;"/>-->
          <!--                    </template>-->
          <!--                </GridColumn>-->
          <GridColumn field="dataName" title="名称" width="350">
            <template slot="cell" slot-scope="{row}">
              <!--            v-Draggable="{row:row,revert:true,proxy:$refs.proxy,dragStart:onDragStart,dragEnd:onDragEnd}"-->
              <!--            v-Droppable="dropOpts" :class="{'over':isover,'dropped':dropped}"-->
              <div draggable="true" class="ellipsis" :title="row.dataName" :data-id="row.id">
                <strong v-if=" queryParams.id==row.id" style="color:red"> {{ row.dataName }}</strong>
                <span v-else> {{ row.dataName }}</span>
              </div>
            </template>
          </GridColumn>
          <GridColumn field="id" title="ID" align="center" width="80px">
            <template slot="cell" slot-scope="{row}">
              <strong v-if=" queryParams.id==row.id" style="color:red"> {{ row.id }}</strong>
              <span v-else> {{ row.id }}</span>
            </template>
          </GridColumn>
          <!--        <GridColumn field="brandNodePath" title="节点路径" align="left" width="250">-->
          <!--          <template slot="cell" slot-scope="{row}">-->
          <!--            <div style="max-width:300px;font-size:0.5em;padding:0 10px;" class="ellipsis" :title="row.brandNodePath">-->
          <!--              <strong v-if=" queryParams.id==row.id" style="color:red"> {{ row.brandNodePath }}</strong>-->
          <!--              <span v-else> {{ row.brandNodePath }}</span>-->
          <!--            </div>-->
          <!--          </template>-->
          <!--        </GridColumn>-->
          <GridColumn field="type" title="分类" align="center" width="100px">
            <template slot="cell" slot-scope="{row}">
              <a-tag :color="row.type|dictName('careerType','color')">{{ row.type|dictName('careerType') }}</a-tag>
            </template>
          </GridColumn>
          <GridColumn title="操作">
            <template slot="cell" slot-scope="{row}">
              <div class="progress">
                <a-icon type="plus-circle" theme="twoTone" v-if="row.type<3"
                        twoToneColor="#4a9ff5"
                        title="新增下级" @click="handleBrand('add',row)"></a-icon>
                <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5"
                        title="修改" @click="handleBrand('modify',row)"></a-icon>
                <a-icon type="delete" theme="twoTone"
                        twoToneColor="#FF9900"
                        title="删除" @click="handleBrand('delete',row)"></a-icon>

                <!--              <a-icon type="up-circle" theme="twoTone"-->
                <!--                      twoToneColor="#4a9ff5"-->
                <!--                      title="排序" @click="handleBrand('sort',row)"></a-icon>-->

              </div>
            </template>
          </GridColumn>
        </TreeGrid>
      </div>
      <div style="flex: 1;margin-top:-40px;">
        <div style="padding-left:10px;font-size:1.2em;margin-bottom:13px"><b>下级：</b></div>
        <sys-career-grid ref="careerGrid"/>
      </div>
    </div>
    <modify-sys-career-modal ref="modifyModal" @success="onNodeUpdated"/>
  </a-card>
</template>

<script>
import Vue from 'vue'
import '@/../static/vx-easyui/dist/themes/default/easyui.css'
import '@/../static/vx-easyui/dist/themes/icon.css'
import '@/../static/vx-easyui/dist/themes/vue.css'
import EasyUI from '@/../static/vx-easyui/dist/vx-easyui-min.js'
import ModifySysCareerModal from "./modifySysCareerModal";
import SysCareerGrid from "./SysCareerGrid";

Vue.use(EasyUI)
/**
 * 本初使用了EasyUI的TreeGrid（https://www.jeasyui.net/vue/697.html），注意修改了两个地方：
 * 1、数据中增加了withoutLeaf，表示所有数据都不是叶子节点
 * 2、删除了节点loading状态展示
 * 3. 拖动参照文档：https://www.jeasyui.net/demo_vue/695.html
 */
export default {
  components: {ModifySysCareerModal, SysCareerGrid},
  data() {
    return {
      loading: false,
      data: [],
      queryParams: {},
      isover: false,
      showLoading: false,
      dropOpts: {
        dragEnter: () => {
          console.log('dragEnter', arguments)
          this.isover = true
        },
        dragLeave: () => {
          console.log('dragLeave', arguments)
          this.isover = false
        },
        drop: () => {
          console.log('drop', arguments)
          this.dropped = true
          this.isover = false
          this.onDrop()
        }
      }
    }
  },
  computed: {
    bodyStyle() {
      return this.isover ? {border: '1px solid #FF9900'} : null
    }
  },
  mounted() {
    this.fetch(this.data, 0)
  },
  methods: {
    reset(id) {
      this.queryParams = {}
      this.fetch(this.data, id || 0)
    },
    search() {
      if (!this.queryParams.id) {
        this.fetch(this.data, 0)
        return
      }
      const that = this
      this.$get('brand/searchById', this.queryParams).then(res => {
        let list = res.data || []

        this.data = list//this.getBrandNodePath(list)
      })
    },
    async fetch(parent, pid) {
      this.loading = true
      this.$get('sysCareer/children', {parentId: pid || 0}).then(res => {
        let list = res.data || []
        if (parent == this.data) {
          this.data = list
        } else {
          parent.children = list
        }
      })
    },
    rowExpand(row) {
      if (row.children) return
      this.fetch(row, row.id)
    },
    nodeClick(row) {
      if (row.type < 3) {
        this.$refs.careerGrid.searchByParent(row.id)
      }
    },
    handleBrand(action, row) {
      switch (action) {
        case 'add':
          this.addChild(row)
          break
        case 'modify':
          this.edit(row)
          break
        case 'delete':
          let that = this
          this.$confirm({
            title: '删除确认',
            content: '您确定要删除【' + row.dataName + '】吗？警告：下级都会被彻底删除，且不可恢复！！',
            centered: true,
            onOk() {
              that.showLoading = true
              that.$post('sysCareer/del', {id: row.id}).then(() => {
                that.showLoading = false
                that.$message.success('删除成功')
                that.onNodeDeleted(row)
              }).catch(() => {
                that.showLoading = false
              })
            },
          })
          break
        case 'sort':
          this.$refs.sortChildren.showModal(row)
          break
      }
    },
    addChild(row) {
      this.$refs.modifyModal.showModal(row, 'new')
    },
    edit(row) {
      this.$refs.modifyModal.showModal(row, 'update')
    },
    onNodeUpdated(rowData, mode) {
      const _this = this;

      let checkChild = function (list = []) {
        list.forEach((one, index) => {
          if (one.id == rowData.id) {
            _this.$set(list, index, rowData)
            return;
          }
          if (one.children) {
            checkChild(one.children)
          }
        })
      }

      if (mode == 'update') {
        checkChild(this.data)
      } else {
        rowData.state = rowData.type == 3 ? "open" : "closed";
        rowData.children = [];

        if (!rowData.parentId) {
          this.data.push(rowData)
        } else {
          this.data.forEach(v => {
            if (v.id === rowData.parentId) {
              v.children.push(rowData);
              return
            }
            if (v.children) {
              v.children.forEach(mmm => {
                if (mmm.id === rowData.parentId) {
                  mmm.children.push(rowData);
                  return
                }
              })
              v.children = v.children.concat([])
            }
          })
        }
      }
    },
    onNodeDeleted(rowData) {
      const _this = this;

      let checkChild = function (list = []) {
        list.forEach((one, index) => {
          if (one.id == rowData.id) {
            list.splice(index, 1)
            return;
          }
          if (one.children) {
            checkChild(one.children)
          }
        })
      }
      checkChild(this.data)
    }
  }
}
</script>
<style lang="less" scoped>
.progress {
  width: 200px;

  .anticon {
    padding: 5px 10px;
  }

}


.dataitem {
  width: 120px;
  height: 130px;
  float: left;
  margin: 6px;
}

.product {
  text-align: center;
}

.product img {
  height: 80px;
  padding: 10px;
}

.product p {
  font-size: 12px;
  margin: 5px 0;
}

.product-proxy {
  //width: 120px;
  padding: 0px 10px;
  height: 30px;
  background: #FF0000;
  border: 1px solid #ccc;
  opacity: 0.6;
  color: #FFF;
}

.over {
  border: 1px solid red;
}
</style>
